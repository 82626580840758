export default {
  login: '登录',
  username: '用户名',
  password: '密码',
  accountTip: '请输入用户名',
  passwordTip: '请输入密码',
  emailTip: '请输入邮箱',
  rememberPassword: '记住密码',
  noAccount: '没有账号？',
  nowRegister: '立即注册',
  register: '注册账号',
  submit: '提交',
  email: '邮箱',
  back: '返回',
  finish: '完成',
  congratulationsRegistration: '恭喜您完成注册',
  registerTip: '您已注册成功，请点击进入登录页',
  nowLogin: '立即登录',
  robot: '机器人',
  robotList: '机器人列表',
  cancel: "取消",
  search: "搜索",
  update: "更改",
  unbind: "解绑",
  workRecord: "任务记录",
  setting: "设置",
  confirmRobot: "确认与机器人解绑",
  Upload_log_and_bag: "上传报告信息",
  confirmWakeRobot: "确认是否唤醒机器人？",
  confirmSleepRobot: "确认是否使机器人休眠?",
  confirmReport: "确认是否导出报告？",
  capture_bag_time:"截取bag时间",
  no: "再想想",
  confirmUnbind: "确认解绑",
  robotRename: '机器人重新命名',
  confirm: "确认",
  robotDetail: "机器人详情",
  workDetail: "任务详情",
  robotName: "名称",
  curBattery: "剩余电量",
  curStatus: "当前状态",
  addRemark: "新增备注",
  accumulatedCleaningArea: "累计清扫面积",
  curLocation: "位置",
  accumulatedCleaningTime: "累计清扫时间",
  robotLabel: "机器人名",
  thingNum: "产品编号",
  mapName: "地图名称",
  workName: "任务名称",
  workType: "任务类型",
  startTime: "开始时间",
  stopTime: "结束时间",
  cleaningArea: "清扫面积",
  cleaningTime: "清扫耗时",
  robotNumber: "序列号",
  bindRobot: "绑定机器人",
  searchRobot: "搜索机器人",
  enter_keyword_search: "输入关键字搜索",
  enter_title_search: "输入标题搜索",
  enter_company_checked_search: "输入公司、审核人搜索",
  filter: "按条件筛选",
  aboutUs: "关于我们",
  updatePassword: "修改密码",
  email_notification: "邮件通知",
  task_start_and_end_reminder:"任务执行完成提醒",
  robot_fault_reminder:"机器故障提醒",
  logout: "退出登录",
  companyAddress: "深圳市南山区明亮科技园2栋105",
  companyPhone: "18366108945",
  companyEmail: "zhaozhi@movexbot.com",
  oldPassword: "原密码",
  newPassword: "新密码",
  confirmPassword: "确认密码",
  oldPasswordTip: "请输入原密码",
  newPasswordTip: "请输入新密码",
  confirmPasswordTip: "请输入确认密码",
  companyInfo: "公司简介",
  noMore: "没有更多了",
  dealStatus: "执行状态",
  status: "状态",
  detailStatus: "处理状态",
  reset: "重置",
  dealOK: "执行成功",
  dealCancel: "取消任务",
  dealError: "异常中断",
  passwordShort: "密码长度至少六位数",
  passwordError: "密码不能输入空格，密码必须是6-16位数字/字符",
  emailError: "邮箱格式有误",
  companyIdError: "公司代码不能为空",
  usernameShort: "用户名/邮箱不能为空",
  codeShort: "验证码不能为空",
  oldPasswordShort: "原密码长度至少六位数",
  newPasswordShort: "新密码长度至少六位数",
  confirmPasswordShort: "确认密码长度至少六位数",
  passwordDiffer: "新密码与确认密码不一样",
  numChat:"业务数据图表",
  allDuring:"总时长：",
  allArea:"总面积：",
  exportNum:"导出业务数据统计报告",
  loginOther:"用户在其他地方登陆",
  robotNameNull:"机器人名不能为空",
  robotNumberNull:"机器人序列号不能为空",
  logoutOk:"确认退出吗？",
  updatePasswordOk:"修改密码提交成功",
  newOldPasswordSame:"新密码和原密码一致，请重新输入",
  forgetPassword: "忘记密码",
  findPassword: "找回密码",
  accountEmailTip: '请输入用户名或邮箱',
  codeTip: '请输入验证码',
  getCode: '获取验证码',
  excelWork: '导出任务报告',
  companyIdTip: "公司代码",
  getAgain: "重新获取",
  sendSMSCodeSuccess: '发送验证码成功',
  sendSMSCodeTo: '验证码已成功发送至',
  exportTaskSuccess: '导出任务成功',
  exporting_report: '正在导出报告……',
  not_online_and_stored_on_the_server: '机器人不在线，已存储到服务器',
  exportTaskStart: '开始导出任务',
  noLocation: '未开启定位',
  locating: '定位中',
  locationSuccess: '定位成功',
  locationWeak: '定位信号弱',
  buildMap: '构图中',
  sleep: '休眠',
  outline: '离线',
  online: '在线',
  standby: '待机',
  working: '工作中',
  pause: '暂停中',
  eStop: '紧急停机',
  deadStop: '碰撞急停',
  inRecordingPath: '录制路径中',
  recordingPathPause: '录制路径暂停',
  cancelTask: '污水回收',
  sewageCapacity: '污水容量',
  residualWater: '剩余水量',
  videoSurveillance:'视频监控',
  monitorplayback:'监控回放',
  percentComplete:'完成百分比',
  exporttaskreportsucceeded:'导出任务报告成功',
  copysuccess:'复制成功',
  copyfailed:'复制失败',
  copy:'复制',
  open:'打开',
  errorInfo:'异常信息',
  error: '异常',
  remoteconnect:'远程机器人连接',
  exported_report_to_mailbox:'清扫报告已发送至邮箱',
  command_sent: '指令已发送',
  insufficient_water:"水量不足",
  sewage_tank_full:"污水箱满",
  low_charge:"电量过低",
  abnormal_battery_communication:"电池通信异常",
  ultrasonic_anomaly:"超声波异常",
  imu_data_exception:"IMU数据异常",
  serial_communication_exception:"串口通信异常",
  lidar_anomaly:"激光雷达异常",
  abnormal_depth_camera:"深度摄像头异常",
  left_drive_exception:"左驱动器异常",
  right_drive_exception:"右驱动器异常",
  multiple_component_exceptions:"多个部件异常",
  fall_risk:"跌落风险",
  version: "版本: ",
  charging: "(充电中)",
  not_supported: "暂不支持",
  loading: "加载中...",
  loosing: "释放即可刷新...",
  trapped: "被困",
  account_is_not_activated: "该账号未激活，请联系管理员激活。",
  docking_failed: "对接失败",
  Fault_information: "请求列表",
  Message_List: '消息列表',
  last_usage: '最后一次使用',
  Message: '消息',
  undetail: "未解决",
  confirmed: "已确认",
  resolved: "已解决",
  delete: "删除",
  hardware: "硬件",
  software: "软件",
  structure: "机械",
  other: "其他",
  new_fault: '新增请求',
  edit_request: '修改请求',
  fault_title: '请输入标题',
  enter_admin: '管理员',
  enter_client: '客户',
  signature: '签名',
  please_select_date:'请选择日期',
  fault_content: '请输入故障详情',
  remark_info: '请输入备注信息',
  fault_time_in: '请选择故障时间',
  fault_file: '选择图片',
  fault_robotId: '请选择机器人',
  fault_remark: '备注信息',
  fault_contact: '请输入联系方式',
  fault_address: '请输入联系地址',
  Fault_no_empty: '故障标题不能为空!',
  Fault_time_no_empty: '故障时间不能为空!',
  Robot_no_empty: '机器人不能为空!',
  Fault_details_no_empty: '故障详情不能为空!',
  select_fault_type:'请选择故障类型!',
  fault_stitle: '故障标题',
  fault_type: '故障类型',
  fault_time: '故障时间',
  record_time: '提交时间',
  fault_robot: '故障机器',
  fault_robot_id: '机器ID',
  note_taker: '提交者',
  fault_scontact: '联系方式',
  fault_detail: '故障详情',
  picture: '图片',
  fault_info: '故障信息',
  last_maintenance: '最后一次维护',
  next_maintenance: '下一次维护',
  submitter_can_delete: '无法执行删除操作',
  successfully_deleted: '删除成功！',
  submitter_and_admin_can_delete: '只有提交人和超级管理员可以修改！',
 admin_can_delete: '超级管理员可以修改！',
  saving: '保存中...',
  save_file_failed: '保存文件失败!',
  remoteconnectTitle: '请确认是否连接机器人',
  remoteconnectTip: '手机将会连接到远程机器，我们保证不会获取手机个人信息。',
  determine: '确定',
  no_con: '取消',
  ok: '确定',
  verification_failed:'验证不通过，请重新登录！',
  location_lost:  '定位异常',
  manual_charge: '手动充电',
  emergency_stop: '紧急按钮被按下',
  collision_trigger: '防撞条触发',
  map_loading_cexception: '地图加载异常',
  robot_id_exception: '机器人ID异常',
  loading_task_exception: '加载任务异常',
  task_occupation: '任务占用',
  no_valid_tasks_found: '未发现有效任务',
  map_selection_error: '地图选择错误',
  manual_mode: '手动按钮被按下',
  failed_to_exit_the_station: '退出基站失败',
  station_location_error: '基站位置错误',
  location_failed: '未定位',
  alarm_information:'报警信息',
  system_information:'状态信息',
  task_information: '任务信息',
  welcome:'欢迎使用！',
  paw_too_error: '你点击太频繁了！',
  after_sale_service: '售后服务',
  maintenance_report: '维护报告',
  repair_report: '维修报告',
  ad_hoc_serviceRequest: "临时请求",
  add_maintenance: "新增维护",
  modify_Maintenance: "修改维护",
  add_repair: "新增维修",
  modify_repair: "修改维修",
  maintenance_details: "维护详情",
  repair_details: "维修详情",
  please_select_the_maintenance_time: "请选择维护时间",
  please_enter_the_company_name: "请输入公司名称",
  please_select_machine_type: "请选择机器类型",
  please_fill_in_the_reviewer: "请填写审核人",
  please_fill_in_the_address: "请填写地址",
  inspect_1: '1.目视检查机器人是否有任何损坏或油漆碎屑',
  inspect_2: '2.检查钥匙和钥匙孔–技术面板',
  inspect_3: '3.打开顶盖–检查油箱周围是否有锈蚀，并调整铰链盖',
  inspect_4: '4.测试“E”停止/屏幕打开和关闭/手动遥控和自动按钮',
  inspect_5: '5.检查平板电脑–确保在Metabot应用程序中/登录和退出/电源/SIM连接',
  inspect_6: '6.打开技术面板–检查电线是否松动或烧毁，并检查控制箱周围是否有水痕',
  inspect_7: '7.检查蓄电池上的蓄电池连接联轴器',
  inspect_8: '8.检查左右车轮/检查脚轮前后',
  inspect_9: '9.调整刮板对齐并检查水箱泄漏/过滤器/废水箱',
  inspect_10: '10.通过创建新的映射来测试超声波传感器/激光雷达传感器/传感器设置',
  inspect_11: '11.加满水并检查水箱/过滤器/废水箱的漏水情况',
  inspect_12: '12.测试刷电机和真空电机功率–所有3级模式',
  inspect_13: '13.检查机器人本体–充电板和充电坞板',
  inspect_14: '14.使用QR码/停车QR码检查充电座',
  inspect_15: '15.使用二维码检查机器人/自动定位的当前位置',
  inspect_16: '16.测试充电器（监控电池寿命）',
  inspect_17: '17.执行功能检查–使用远程手动清洁任务–所有清洁模式',
  inspect_18: '18.执行功能检查–使用新映射和路径执行自动清洁任务–所有清洁模式',
  inspect_19: '19.在当前地图上检查和操作机器人–监控路径是否有异常',
  inspect_20: '20.检查映射区域/斜坡区域/自动扶梯区域上的虚拟墙/障碍物',
  inspect_21: '21.检查机器人避障/反应/摄像机校准/悬崖检测（沿墙壁路径创建）',
  inspect_22: '22.测试自动对接并确保对接台板与机器人主体充电板齐平（调整充电台上的高度）',
  inspect_23: '23.测试水泵/污水软管/自动废水排放–所有清洁模式',
  inspect_24: '24.系统软件升级',
  inspect_25: '25.基本操作员维护刷新',
  question: '问题',
  clear: '清除',
  undo: '撤消',
  finished: '完成',
  please_sign:'请签字',
  admin_not_signed: '管理员未签名',
  client_not_signed: '客户未签名',
  normal: '正常',
  Single_chip_no_user: '单片机调试口无法使用',
  other_errors: '单片机调试口其他的错误',
  single_chip_errors: '单片机调试口数据异常',
  meter_no_response: '库仑计通讯无响应',
  meter_other_anomalies: '库仑计通讯其他异常',
  meter_data_abnormity: '库仑计通讯数据异常',
  ui_no_response: '超声波通讯口无响应',
  ui_other_anomalies: '超声波通讯其他异常',
  ui_data_abnormity: '超声波通讯数据异常',
  ui_Control_Module_one: '超声波控制模组1异常',
  ui_Control_Module_two: '超声波控制模组2异常',
  ui_error_one: '超声波1异常',
  ui_error_two: '超声波2异常',
  ui_error_three: '超声波3异常',
  ui_error_four: '超声波4异常',
  ui_error_five: '超声波5异常',
  ui_error_six: '超声波6异常',
  ui_error_seven: '超声波7异常',
  ui_error_eigth: '超声波8异常',
  ui_error_nine: '超声波9异常',
  ui_error_ten: '超声波10异常',
  ui_error_eleven: '超声波11异常',
  ui_error_Twelve: '超声波12异常',
  Abnormal_probe_one_low: '超声波模组1中1~8 有异常探头',
  Abnormal_probe_one_higth: '超声波模组1中9~12 有异常探头',
  Abnormal_probe_two_low: '超声波模组2中1~8 有异常探头',
  Abnormal_probe_two_higth: '超声波模组2中9~12 有异常探头',
  single_chip_com_error: '单片机通讯异常',
  single_chip_com_error_other: '单片机通讯其他异常',
  single_chip_com_error_data: '单片机通讯数据异常',
  imu_no_response: 'IMU无响应',
  imu_other_anomalies: 'IMU其他异常',
  imu_data_abnormity: 'IMU数据异常',
  Handle_exception: '手柄异常',
  com_no_find: '通讯口未找到',
  com_no_data: '通讯口无数据',
  six_line_no_find: '16线雷达找不到设备',
  six_line_no_data: '16线雷达无数据',
  Horizontal_radar_no_find: '水平雷达找不到设备',
  Horizontal_radar_no_data: '水平雷达无数据',
  Oblique_downward_radar_no_find: '斜向下的雷达找不到设备',
  Oblique_downward_radar_no_data: '斜向下的雷达无数据',
  Left_drive_control_exception: '左驱动器控制异常',
  Left_Driver_Contact_Abnormality: '左驱动器接触异常',
  Left_Driver_Overflow: '左驱器过流',
  Right_Driver_Control_Exception: '右驱动器控制异常',
  Right_Driver_Contact_Abnormality: '右驱动器接触异常',
  Right_Driver_Overcurrent: '右驱动器过流',
  Left_push_rod_not_returned_to_position: '排污推杆收回异常',
  Left_push_rod_is_not_put_in_place: '排污推杆伸出异常',
  cooling_fan_abnormal_of_the_control_box: '控制箱散热风扇异常',
  right_push_rod_not_returned_to_position: '右推杆未回到位',
  right_push_rod_is_not_put_in_place: '右推杆未推到位到位',
  Front_Camera_no_Find_Device: '前摄像头找不到设备',
  Front_camera_has_no_data: '前摄像头无数据',
  Back_Camera_no_Find_Devices: '后摄像头找不到设备',
  Back_camera_has_no_data: '后摄像头无数据',
  Left_drive_overcurrent: '左驱动器过流',
  Left_drive_overload: '左驱动器过载',
  Left_motor_feedback_signal_error: '左马达回授信号错误',
  Left_drive_overvoltage: '左驱动器过压',
  Left_motor_undervoltage: '左马达欠压',
  Left_drive_overheating: '左驱动器过温',
  Failure_to_start_left_motor: '左马达启动失败',
  Left_Driver_EEP_Data_Error: '左驱动器EEP资料错误',
  Left_motor_overheating: '左马达过温',
  Left_motor_overspeed: '左马达超速',
  Left_Drive_ENCODE_Error: '左驱动ENCODE错误',
  Initial_operation_of_left_motor_prohibited: '左马达初期运转禁止',
  Left_motor_external_stop: '左马达外部停止',
  Left_Driver_Hall_Sequence_Error: '左驱动器霍尔序列错误',
  Left_Driver_Communication_Instruction_Abnormal: '左驱动器通讯指令异常',
  Left_Driver_Parameter_Setting_Error: '左驱动器参数设定错误',
  Right_Driver_Overload: '右驱动器过载',
  Right_motor_feedback_signal_error: '右马达回授信号错误',
  Right_Driver_Overvoltage: '右驱动器过压',
  Right_motor_undervoltage: '右马达欠压',
  Right_Horse_Driver_Overheating: '右马驱动器过温',
  Failure_of_startup_of_right_motor: '右马达启动失败',
  Right_Driver_EEP_Data_Error: '右驱动器EEP资料错误',
  Right_motor_overheating: '右马达过温',
  Right_motor_overspeed: '右马达超速',
  Right_Driver_ENCODE_Error: '右驱动器ENCODE 错误',
  Initial_operation_of_right_motor_prohibited: '右马达初期运转禁止',
  Right_motor_external_stop: '右马达外部停止',
  Right_Driver_Hall_Sequence_Error: '右驱动器霍尔序列错误',
  Right_Driver_Communication_Instruction_Abnormal: '右驱动器通讯指令异常',
  Error_setting_of_right_driver_parameters: '右驱动器参数设定错误',
  enter_emergency_stop_state: ' 进入急停状态',
  depth_camera_0_abnormal: '深度摄像头0异常',
  depth_camera_1_abnormal: '深度摄像头1异常',
  depth_camera_2_abnormal: '深度摄像头2异常',
  depth_camera_3_abnormal: '深度摄像头3异常',
  please_enter_administrator: '请输入管理员',
  please_select_date_for_admin: '请管理员选择日期',
  please_enter_client: '请输入客户',
  client_select_date: '请客户选择日期',
  no_data: '无',
  CLEAN_TASK_SUCCEED: '执行成功',
  CLEAN_TASK_FAILED: '执行任务失败',
  CLEAN_TASK_CANCEL: '定位失败,请重新确认地图是否匹配',
  CLEAN_TASK_LOW_BATTERY_FAILED: '电量过低，执行任务失败！',
  CLEAN_TASK_COULOMETER_ERROR_FAILED: '库仑计故障，执行任务失败！',
  CLEAN_TASK_ULTRASONIC_ERROR_FAILED: '超声波异常，执行任务失败！',
  CLEAN_TASK_IMU_ERROR_FAILED: '陀螺仪传感器异常，执行任务失败！',
  CLEAN_TASK_SERIAL_PORT_ERROR_FAILED: '串口数据异常，执行任务失败',
  CLEAN_TASK_HORIZONTAL_LIDAR_ERROR_FAILED: '水平雷达异常，执行任务失败！',
  CLEAN_TASK_INCLINED_LIDAR_ERROR_FAILED: '斜向下雷达异常，执行任务失败！',
  CLEAN_TASK_FRONT_CAMERA_ERROR_FAILED: '前置摄像头数据异常，执行任务失败！',
  CLEAN_TASK_LEFT_DRIVER_ERROR_FAILED: '左驱动器异常，执行任务失败！',
  CLEAN_TASK_RIGHT_DRIVER_ERROR_FAILED: '右驱动器异常，执行任务失败！',
  CLEAN_TASK_MUTIPLE_ERROR_FAILED: '多个部件异常，执行任务失败！',
  CLEAN_TASK_MIDDLE_BRUSH_ERROR_FAILED: '中刷异常',
  CLEAN_TASK_MANUAL_BCHARGING: '后面正在充电',
  CLEAN_TASK_EMERGENCY_STOP: '执行失败，机器人处于急停状态',
  CLEAN_TASK_BUMPER_TRIC: '执行失败，机器人碰撞后处于急停状态',
  CLEAN_TASK_NO_DUSTBIN: '没有垃圾箱',
  TASK_RESULT_NO_MAP: '没有地图',
  TASK_RESULT_NO_ROBOT_ID: '没有机器人ID',
  TASK_RESULT_LOAD_TASK_ERROR: '加载任务失败',
  TASK_RESULT_BUSY: '执行任务中',
  TASK_RESULT_NO_VALID_TASK: '未找到有效任务，请检查机器人是否可以到达清洁区域',
  MAP_LOADING_EXCEPTION: '地图加载异常',
  DRIVING_WHEEL_RELEASE_BUTTON_PRESSED: '主动轮释放按钮已按下',
  INSUFFICIENT_WATER: '水量不足',
  THE_SEWAGE_TANK_IS_FULL: '污水箱已满',
  FAILED_TO_EXIT_THE_BASE_STATION: '退出基站失败',
  ABNORMAL_BASE_STATION_POSITION_OR_CHARGING: '基站位置或者充电异常',
  setup_successful: '设置成功',
  cleaned_area: '已清扫面积：',
  battery_usage: '电量消耗',
  water_usage: '水量消耗',
  clean_mode: '清洁模式',
  completion_rate: '完成百分比：',
  time_estimated: '预计还需：',
  cancel_task: '取消任务',
  pause_task: '暂停任务',
  resume_task: '恢复任务',
  start_task: '开始任务',
  nomore: '不能再多了',
  error_100: '刷盘门已开启',
  error_101: '顶盖已开启',
  error_114: 'IMU其他异常',
  error_115: 'IMU数据异常',
  error_120: '通讯口无数据',
  error_121: '超声波1异常',
  error_122: '超声波2异常',
  error_123: '超声波3异常',
  error_124: '超声波4异常',
  error_125: '超声波5异常',
  error_126: '超声波6异常',
  error_127: '超声波7异常',
  error_128: '超声波8异常',
  error_129: '超声波9异常',
  error_130: '超声波10异常',
  error_134: '风机过流报警',
  error_135: '刷盘过流报警',
  error_136: '排污推杆过流报警',
  error_137: '刮条推杆过流报警',
  error_138: '刷盘推杆过流报警',
  error_139: '驱动板过温报警',
  error_140: '排污推杆收回异常',
  error_141: '排污推杆伸出异常',
  error_160: '左电机过温报警',
  error_161: '左驱动器输出短路',
  error_162: '左马达回授信号错误',
  error_163: '左驱动器母线电压过高',
  error_164: '左驱动器母线电压过低',
  error_165: '左驱动器温度过高',
  error_166: '左速度跟随误差超过允许值',
  error_167: '左编码器计数错误',
  error_168: '保留备用',
  error_169: 'I2*T 故障(驱动器或电机过载)',
  error_170: '内部错误',
  error_171: '左驱动器制动电阻过温报警',
  error_172: '左电机实际跟踪误差超过允许值',
  error_173: '左电机编码器 ABZ 信号错误',
  error_174: '左电机寻找电机错误(通讯式编码器)',
  error_175: '左电机编码器 UVW 信号错误',
  error_176: '右电机过温报警',
  error_177: '右驱动器输出短路',
  error_178: '右马达回授信号错误',
  error_179: '右驱动器母线电压过高',
  error_180: '右驱动器母线电压过低',
  error_181: '右驱动器温度过高',
  error_182: '右速度跟随误差超过允许值',
  error_183: '右编码器计数错误',
  error_184: '保留备用',
  error_185: 'I2*T 故障(驱动器或电机过载)',
  error_186: '内部错误',
  error_187: '右驱动器制动电阻过温报警',
  error_188: '右电机实际跟踪误差超过允许值',
  error_189: '右电机编码器 ABZ 信号错误',
  error_190: '右电机寻找电机错误(通讯式编码器)',
  error_191: '右电机编码器 UVW 信号错误',
  error_192: '控制盒散热风扇异常',
  error_193: '流量计报警',

  error_203: '超声波1异常',
  error_204: '超声波2异常',
  error_205: '超声波3异常',
  error_206: '超声波4异常',
  error_207: '超声波5异常',
  error_208: '超声波6异常',
  error_209: '超声波7异常',
  error_210: '超声波8异常',
  error_211: '超声波9异常',
  error_212: '超声波10异常',
  error_213: '超声波11异常',
  error_214: '超声波12异常',
  error_222: '激光雷达异常',
  error_223: '深度摄像头0异常',
  error_224: '深度摄像头1异常',
  error_225: '深度摄像头2异常',
  error_226: '深度摄像头3异常',
  error_227: '控制箱散热风扇异常',
  error_228: '机器人配置文件异常',
  error_229: '深度摄像头4异常',
  error_300: '极片错误',
  error_301: '水枪信号错误',
  error_302: '温度错误',
  error_303: '清水箱空错误',
  error_304: '污水箱满错误'
}