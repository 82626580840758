export default {
  login: 'Login',
  username: 'Username',
  password: 'Password',
  accountTip: 'Please enter user name',
  passwordTip: 'Please enter the password',
  emailTip: 'Please input your email',
  rememberPassword: 'Remember password',
  noAccount: 'No account?',
  nowRegister: 'Sign up now',
  register: 'Register account',
  submit: 'Submit',
  email: 'Email',
  back: 'Back',
  finish: 'Finish',
  congratulationsRegistration: 'Congratulations on your registration',
  registerTip: 'You have successfully registered, please click to enter the login page',
  nowLogin: 'Login immediately',
  robot: 'Robot',
  robotList: 'RobotList',
  cancel: "Cancel",
  search: "Search",
  update: "Update",
  unbind: "Unbind",
  workRecord: "Report",
  setting: "Setting",
  confirmRobot: "Confirm to unbind from the robot",
  Upload_log_and_bag: "Upload report information",
  confirmWakeRobot: "Confirm whether to wake up the robot？",
  capture_bag_time:"Capture bag time",
  confirmSleepRobot: "Confirm whether to sleep the robot?",
  confirmReport: "Are you sure you want to export the report?",
  no: "Think again",
  confirmUnbind: "Confirm unbinding",
  robotRename: 'Robot rename',
  confirm: "Confirm",
  robotDetail: "Robot details",
  workDetail: "Task details",
  robotName: "Name",
  curBattery: "Remaining battery",
  curStatus: "Current state",
  addRemark: "New Remarks",
  accumulatedCleaningArea: "Cumulative cleaning area",
  curLocation: "Location",
  accumulatedCleaningTime: "Cumulative cleaning time",
  robotLabel: "Robot name",
  thingNum: "Product number",
  mapName: "Map name",
  workName: "Task name",
  workType: "Task type",
  startTime: "StartTime",
  stopTime: "StopTime",
  cleaningArea: "CleaningArea",
  cleaningTime: "CleaningTime",
  robotNumber: "SN",
  bindRobot: "BindRobot",
  searchRobot: "SearchRobot",
  enter_keyword_search: "Enter keyword search",
  enter_title_search: "Enter title search",
  enter_company_checked_search: "Enter company and reviewer search",
  filter: "Filter",
  aboutUs: "AboutUs",
  updatePassword: "Change Password",
  email_notification: "Email Notification",
  task_start_and_end_reminder:"Task execution completion reminder",
  robot_fault_reminder:"Robot Fault Reminder",
  logout: "Logout",
  companyAddress: "深圳市南山区明亮科技园2栋105",
  companyPhone: "18366108945",
  companyEmail: "zhaozhi@movexbot.com",
  oldPassword: "Old",
  newPassword: "New",
  confirmPassword: "Confirm",
  oldPasswordTip: "Enter old password",
  newPasswordTip: "Enter new password",
  confirmPasswordTip: "Confirm password",
  companyInfo: "CompanyInfo",
  noMore: "NoMore",
  dealStatus: "Execution status",
  status: "Status",
  detailStatus: "Current State",
  reset: "Reset",
  dealOK: "Execution succeed",
  dealCancel: "Cancel task",
  dealError: "Abnormal interrupt",
  passwordShort: "The password must be at least six digits in length",
  passwordError: "Password cannot enter spaces, password must be 6-16 digits/characters",
  emailError: "Incorrect email format",
  companyIdError: "Company code cannot be empty",
  usernameShort: "Username can not be empty",
  oldPasswordShort: "The original password is at least six digits in length",
  newPasswordShort: "The new password must be at least six digits long",
  confirmPasswordShort: "Confirm that the password is at least six digits in length",
  passwordDiffer: "The new password is not the same as the confirmed password",
  numChat:"Business data chart",
  allDuring: "Total Duration:",
  allArea: "Total Area:",
  exportNum: "Export Business Data Statistics Report",
  loginOther: "The user is logged in elsewhere",
  robotNameNull: "The robot name cannot be empty",
  robotNumberNull: "The robot serial number cannot be empty",
  logoutOk: "Are you sure to exit?",
  updatePasswordOk: "Modify the password submitted successfully",
  newOldPasswordSame: "The new password is the same as the original password, please re-enter",
  forgetPassword: "Forgot Password",
  findPassword: "Find Password",
  accountEmailTip:'Please enter your username or email',
  codeTip:'Verification code',
  getCode:'Get verification code',
  excelWork:'Export task report',
  companyIdTip: "Company Code",
  getAgain: "Regain",
  sendSMSCodeSuccess:'Send verification code successfully',
  sendSMSCodeTo:'The verification code has been successfully sent to',
  exportTaskSuccess:'Export task success',
  exporting_report: 'Exporting report……',
  not_online_and_stored_on_the_server: 'Not online and stored on the server',
  exportTaskStart:'Start export task',
  noLocation: 'Slam State Standby',
  locating: 'Locating',
  locationSuccess: 'Location Success',
  locationWeak: 'Location Weak',
  buildMap: 'Mapping',
  sleep: 'Sleep',
  outline: 'Offline',
  error: 'Error',
  online: 'Online',
  standby: 'Standby',
  working: 'Working',
  pause: 'Suspended',
  eStop: 'Emergency Stop',
  deadStop: 'Collision Emergency Stop',
  inRecordingPath: 'Recording Path',
  recordingPathPause: 'Recording Pause',
  cancelTask: 'Recycling water',
  sewageCapacity: 'Waste water',
  residualWater: 'Clean water',
  videoSurveillance:'Monitor',
  monitorplayback:'Playback',
  percentComplete:'Percent complete',
  exporttaskreportsucceeded:'Export task report succeeded',
  copysuccess:'Copy successfully',
  copyfailed:'Copy failed',
  copy:'Copy',
  open:'Open',
  remoteconnect:'Remote robot connection',
  exported_report_to_mailbox:'sending to mail',
  command_sent: 'Command sent',
  insufficient_water:"Insufficient water",
  sewage_tank_full:"Sewage tank full",
  low_charge:"Low battery",
  abnormal_battery_communication:"Abnormal battery communication",
  ultrasonic_anomaly:"Ultrasonic anomaly",
  imu_data_exception:"IMU data exception",
  serial_communication_exception:"Serial communication exception",
  lidar_anomaly:"Lidar anomaly",
  abnormal_depth_camera:"Abnormal depth camera",
  left_drive_exception:"Left drive exception",
  right_drive_exception:"Right drive exception",
  multiple_component_exceptions:"Multiple component exceptions",
  fall_risk:"Fall risk",
  version: "Version: ",
  charging: "(Charging)",
  not_supported: "Not supported at the moment",
  loading: "Loading...",
  loosing: "Release to refresh...",
  trapped: "Trapped",
  account_is_not_activated: "This account is not activated, please contact the administrator to activate it.",
  docking_failed: "Docking Failed",
  Fault_information: "Request List",
  Message_List: 'Message List',
  Message: 'Message',
  undetail: "Unsolved",
  resolved: "Resolved",
  confirmed: "Confirmed",
  delete: "Delete",
  hardware: "Hardware",
  software: "Software",
  structure: "Machine",
  other: "Other",
  new_fault: 'New Request',
  edit_request: 'Edit Request',
  fault_title: 'Please enter a title',
  enter_admin: 'Admin',
  enter_client: 'Client',
  signature: 'signature',
  please_select_date:'Please select date',
  fault_content: 'Please enter the fault details',
  remark_info: 'Please enter remark',
  fault_time_in: 'Please select the fault time',
  fault_file: 'Select Picture',
  fault_robotId: 'Please select robot',
  fault_remark: 'Remarks',
  fault_contact: 'Please enter the contact information',
  fault_address: 'Please enter the contact address',
  Fault_no_empty: 'Fault title cannot be empty',
  Fault_time_no_empty: 'Fault time cannot be empty',
  Robot_no_empty: 'Robot cannot be empty',
  Fault_details_no_empty: 'Fault details cannot be empty',
  select_fault_type:'Please select the fault type',
  fault_stitle: 'Fault Title',
  fault_type: 'Fault Type',
  fault_time: 'Fault Time',
  record_time: 'Record Time',
  fault_robot: 'Machine',
  fault_robot_id: 'RobotId',
  note_taker: 'Note-taker',
  fault_scontact: 'Contact Way',
  fault_detail: 'Fault Details',
  picture: 'Picture',
  fault_info: 'Fault Info',
  last_maintenance: 'Last Checked',
  next_maintenance: 'Next Checked',
  last_usage: 'Last Usage',
  submitter_can_delete: 'Unable to perform delete operation',
  successfully_deleted: 'Successfully deleted',
  submitter_and_admin_can_delete: 'Only the submitter and super administrator can modify!',
  admin_can_delete: 'Only the super administrator can modify',
  saving: 'Saving...',
  save_file_failed: 'Failed to save file!',
  remoteconnectTitle: 'Please confirm whether you need to connect to the robot',
  remoteconnectTip: 'The mobile phone will be connected to the robot. We guarantee that we will not obtain the personal information of the mobile phone.',
  determine: 'Yes',
  no_con: 'No',
  ok: 'OK',
  verification_failed:'Verification failed, please login again!',
  location_lost: 'Location error',
  manual_charge: 'Manual charge',
  emergency_stop: 'Emergency stop',
  collision_trigger: 'Collision  trigger',
  map_loading_cexception: 'Map loading exception',
  robot_id_exception: 'Robot ID exception',
  loading_task_exception: 'Loading task exception',
  task_occupation: 'Task occupation',
  no_valid_tasks_found: 'No valid tasks found',
  map_selection_error: 'Map selection error',
  manual_mode: 'Manual mode',
  failed_to_exit_the_station: 'Failed to exit the station',
  station_location_error: 'Station location error',
  location_failed: 'Not Located',
  alarm_information:'Alarm information',
  system_information:'State information',
  please_enter_the_company_name: "Please enter the company name",
  welcome:'Welcome！',
  task_information: 'Task Information',
  paw_too_error: 'You click too often',
  after_sale_service: 'After-sales',
  maintenance_report: 'Maintenance',
  repair_report: 'Repair',
  add_maintenance: "Add Maintenance",
  modify_Maintenance: "Modify Maintenance",
  modify_repair: "Modify Repair",
  add_repair: "Add Repair",
  repair_details: "Repair Details",
  maintenance_details: "Maintenance Details",
  ad_hoc_serviceRequest: "Request",
  please_select_the_maintenance_time: "Please select maintenance time",
  please_select_machine_type: "Please select machine type",
  please_fill_in_the_reviewer: "Please fill in the reviewer",
  please_fill_in_the_address: "Please fill in the address",
  inspect_1: '1.Physical inspect robot visually for any damages or chip paint',
  inspect_2: '2.Inspect Keys & Key Holes – Technical Panel',
  inspect_3: '3.Open Top Cover – Inspect any rust found around tank & adjust hinges cover',
  inspect_4: '4.Test ‘E’ Stop / Screen on & off / Manual remote & Auto Buttons',
  inspect_5: '5.Inspect Tablet – Ensure in Metabots App / Log in & out / Power / SIM Connection',
  inspect_6: '6.Open Technical Panel – Inspect for loose wires or burned wires & Inspect surroundings for water marks on control box',
  inspect_7: '7.Check for battery connection couplings on battery',
  inspect_8: '8.Inspect left & right wheels / Inspect castor wheel front & back',
  inspect_9: '9.Adjust squeegee bar alignment and Inspect tank leak / Filters / Waste water tank',
  inspect_10: '10.Test ultra sonic sensors / Lidar sensors / Sensors setting by creating new mapping',
  inspect_11: '11.Fill up water & inspect water leakage from tank / Filters / Waste water tan',
  inspect_12: '12.Test brush motor & Vacuum motor power – all 3 level modes',
  inspect_13: '13.Inspect robot body – charging plates and charging dock plates',
  inspect_14: '14.Inspect Charging Dock with QR Code / Parking QR code',
  inspect_15: '15.Check on current location of robot / Auto Localization with QR code',
  inspect_16: '16.Test Charger (Monitor battery life) ',
  inspect_17: '17.Perform functionality check – Manual cleaning task using remote – all cleaning modes',
  inspect_18: '18.Perform functionality check – Auto cleaning task with new mapping & path – all cleaning modes',
  inspect_19: '19.Inspect and operate the robot on current map – Monitor pathing for any abnormalities',
  inspect_20: '20.Check virtual wall / obstacles on mapped area / slope area / escalator area',
  inspect_21: '21.Check robot obstacle avoidance / reaction / camera calibration / cliff detection (create along wall path)',
  inspect_22: '22.Test auto docking & ensure docking station plate is leveled with robot body charging plate (Adjust height on charging dock)',
  inspect_23: '23.Test water pump / sewage hose / auto waste water discharge – all cleaning modes',
  inspect_24: '24.System software upgrade',
  inspect_25: '25.Basic Operator Maintenance Refresher',
  question: 'Issue',
  clear: 'Clear',
  undo: 'Undo',
  please_sign: 'Please Sign',
  finished: 'Finished',
  admin_not_signed: 'admin not signed',
  client_not_signed: 'client not signed',
  normal: 'Normal',
  Single_chip_no_user: 'MCU Debug Port Cannot Be Used',
  other_errors: 'MCU Debug Port Other Errors',
  single_chip_errors: 'MCU Debug Port Data Abnormal',
  meter_no_response: ' Coulomb Communication Not Responsing',
  meter_other_anomalies: 'Other Abnormalities Of Coulomb Communication',
  meter_data_abnormity: 'Coulomb Communication Data Abnormal',
  ui_no_response: 'Ultrasonic Communication Port Not Responsing',
  ui_other_anomalies: 'Ultrasonic Communication Other Anomalies',
  ui_data_abnormity: 'Ultrasonic communication data abnormity',
  ui_Control_Module_one: 'Ultrasonic control module 1 is abnormal',
  ui_Control_Module_two: 'Ultrasonic control module 2 is abnormal',
  ui_error_one: 'Ultrasonic  1 is abnormal',
  ui_error_two: 'Ultrasonic  2 is abnormal',
  ui_error_three: 'Ultrasonic  3 is abnormal',
  ui_error_four: 'Ultrasonic  4 is abnormal',
  ui_error_five: 'Ultrasonic  5 is abnormal',
  ui_error_six: 'Ultrasonic  6 is abnormal',
  ui_error_seven: 'Ultrasonic  7 is abnormal',
  ui_error_eigth: 'Ultrasonic  8 is abnormal',
  ui_error_nine: 'Ultrasonic  9 is abnormal',
  ui_error_ten: 'Ultrasonic  10 is abnormal',
  ui_error_eleven: 'Ultrasonic  11 is abnormal',
  ui_error_Twelve: 'Ultrasonic  12 is abnormal',
  Abnormal_probe_one_low: '1~8 in the ultrasonic module 1 have abnormal probe',
  Abnormal_probe_one_higth: '9~12 in the ultrasonic module 1 have abnormal probe',
  Abnormal_probe_two_low: '1~8 in the ultrasonic module 2 have abnormal probe',
  Abnormal_probe_two_higth: '9~12 in the ultrasonic module 2 have abnormal probe',
  single_chip_com_error: 'MCU communication abnormality',
  single_chip_com_error_other: 'MCU communication other abnormalities',
  single_chip_com_error_data: 'MCU communication data is abnormal',
  imu_no_response: 'IMU no response',
  imu_other_anomalies: 'IMU other anomalies',
  imu_data_abnormity: 'IMU data abnormity',
  Handle_exception: 'Abnormal handle',
  com_no_find: 'Communication port not found',
  com_no_data: 'No data in the communication port',
  six_line_no_find: 'line 16 radar not find equipment',
  six_line_no_data: 'line 16 radar has no data',
  Horizontal_radar_no_find: 'Horizontal radar not find equipment',
  Horizontal_radar_no_data: 'Horizontal radar has no data',
  Oblique_downward_radar_no_find: 'Oblique downward radar not find equipment',
  Oblique_downward_radar_no_data: 'Oblique downward radar has no data',
  Left_drive_control_exception: 'Left driver control exception',
  Left_Driver_Contact_Abnormality: 'Left Driver Contact Abnormality',
  Left_Driver_Overflow: 'Left Driver overcurrent',
  Right_Driver_Control_Exception: 'Right Driver Control Exception',
  Right_Driver_Contact_Abnormality: 'Right Driver Contact Abnormality',
  Right_Driver_Overcurrent: 'Right Driver Overcurrent',
  Left_push_rod_not_returned_to_position: 'Retracting abnormal of sewage push rod',
  Left_push_rod_is_not_put_in_place: 'Extending abnormal of sewage push rod',
  cooling_fan_abnormal_of_the_control_box: 'Cooling fan abnormal of the control box',
  right_push_rod_not_returned_to_position: 'right push rod not returned to position',
  right_push_rod_is_not_put_in_place: 'right push rod is not put in place',
  Front_Camera_no_Find_Device: 'Front Camera not Find Device',
  Front_camera_has_no_data: 'Front camera has no data',
  Back_Camera_no_Find_Devices: 'Back Camera not Find Devices',
  Back_camera_has_no_data: 'Back camera has no data',
  Left_drive_overcurrent: 'Left driver overcurrent',
  Left_drive_overload: 'Left driver overload',
  Left_motor_feedback_signal_error: 'Left motor feedback signal error',
  Left_drive_overvoltage: 'Left drive overvoltage',
  Left_motor_undervoltage: 'Left motor undervoltage',
  Left_drive_overheating: 'Left driver overheating',
  Failure_to_start_left_motor: 'Left motor failed to start',
  Left_Driver_EEP_Data_Error: 'Left Driver EEP Data Error',
  Left_motor_overheating: 'Left motor overheating',
  Left_motor_overspeed: 'Left motor overspeed',
  Left_Drive_ENCODE_Error: 'Left Drive ENCODE Error',
  Initial_operation_of_left_motor_prohibited: 'Left motor initial operation prohibited',
  Left_motor_external_stop: 'Left motor external stop',
  Left_Driver_Hall_Sequence_Error: 'Left Driver Hall Sequence Error',
  Left_Driver_Communication_Instruction_Abnormal: 'Left Driver Communication Instruction Abnormal',
  Left_Driver_Parameter_Setting_Error: 'Left Driver Parameter Setting Error',
  Right_Driver_Overload: 'Right Driver Overload',
  Right_motor_feedback_signal_error: 'Right motor feedback signal error',
  Right_Driver_Overvoltage: 'Right Driver Overvoltage',
  Right_motor_undervoltage: 'Right motor undervoltage',
  Right_Horse_Driver_Overheating: 'Right Horse Driver Overheating',
  Failure_of_startup_of_right_motor: 'Failure of startup of right motor',
  Right_Driver_EEP_Data_Error: 'Right Driver EEP Data Error',
  Right_motor_overheating: 'Right motor overheating',
  Right_motor_overspeed: 'Right motor overspeed',
  Right_Driver_ENCODE_Error: 'Right Driver ENCODE Error',
  Initial_operation_of_right_motor_prohibited: 'Initial operation of right motor prohibited',
  Right_motor_external_stop: 'Right motor external stop',
  Right_Driver_Hall_Sequence_Error: 'Right Driver Hall Sequence Error',
  Right_Driver_Communication_Instruction_Abnormal: 'Right Driver Communication Instruction Abnormal',
  Error_setting_of_right_driver_parameters: 'Error setting of right driver parameters',
  enter_emergency_stop_state: ' Emergency stop',
  depth_camera_0_abnormal: 'Depth camera 0 abnormal',
  depth_camera_1_abnormal: 'Depth camera 1 abnormal',
  depth_camera_2_abnormal: 'Depth camera 2 abnormal',
  depth_camera_3_abnormal: 'Depth camera 3 abnormal',
  please_enter_administrator: 'Please enter admin',
  please_select_date_for_admin: 'Admin selects date',
  please_enter_client: 'Please enter client',
  client_select_date: 'Client select date',
  no_data: 'No Data',
  CLEAN_TASK_SUCCEED:	'Task succeed',
  CLEAN_TASK_FAILED:	'Unable to perform task',
  CLEAN_TASK_CANCEL:	'The location failed. Please re-confirm whether the map matches',
  CLEAN_TASK_LOW_BATTERY_FAILED:	'The battery is too low and the task is failed',
  CLEAN_TASK_COULOMETER_ERROR_FAILED:	'Coulomb failure, execution failure',
  CLEAN_TASK_ULTRASONIC_ERROR_FAILED:	'Ultrasonic abnormality, execution failure',
  CLEAN_TASK_IMU_ERROR_FAILED:	'Gyro sensor abnormality, execution failure',
  CLEAN_TASK_SERIAL_PORT_ERROR_FAILED:	'Serial port abnormal，execution failure',
  CLEAN_TASK_HORIZONTAL_LIDAR_ERROR_FAILED:	'Horizontal radar anomaly，execution failure',
  CLEAN_TASK_INCLINED_LIDAR_ERROR_FAILED:	'Oblique down radar anomaly，execution failure',
  CLEAN_TASK_FRONT_CAMERA_ERROR_FAILED:	'Front camera data abnormal，execution failure',
  CLEAN_TASK_LEFT_DRIVER_ERROR_FAILED:	'The left drive is abnormal and the task execution failed',
  CLEAN_TASK_RIGHT_DRIVER_ERROR_FAILED:	'Right drive abnormal，execution failure',
  CLEAN_TASK_MUTIPLE_ERROR_FAILED:	'Multiple component abnormal，execution failure',
  CLEAN_TASK_MIDDLE_BRUSH_ERROR_FAILED:	'Abnormal brush',
  CLEAN_TASK_MANUAL_BCHARGING:	'Manual charging',
  CLEAN_TASK_EMERGENCY_STOP:	'Execution failed, the robot is in an emergency stop state',
  CLEAN_TASK_BUMPER_TRIC:	'The execution fails, and the robot enters the emergency stop state after the collision',
  CLEAN_TASK_NO_DUSTBIN:	'No dustbin',
  TASK_RESULT_NO_MAP:	'No map',
  TASK_RESULT_NO_ROBOT_ID:	'No robot ID',
  TASK_RESULT_LOAD_TASK_ERROR:	'Failed to load task',
  TASK_RESULT_BUSY:	'Performing task',
  TASK_RESULT_NO_VALID_TASK:	'No valid task found, please check whether the robot can reach the cleaning area',
  MAP_LOADING_EXCEPTION:	'Map loading exception',
  DRIVING_WHEEL_RELEASE_BUTTON_PRESSED:	'Please switch to automatic mode',
  INSUFFICIENT_WATER:	'Insufficient Water',
  THE_SEWAGE_TANK_IS_FULL:	'The sewage tank is full',
  FAILED_TO_EXIT_THE_BASE_STATION:	'Failed to exit the base station',
  ABNORMAL_BASE_STATION_POSITION_OR_CHARGING:	'Abnormal base station position or charging',
  setup_successful: 'Setup successful',
  cleaned_area: 'Cleaned area：',
  water_usage: 'Water Usage',
  clean_mode: 'Clean Mode',
  battery_usage: 'Battery Usage',
  completion_rate: 'Completion rate：',
  time_estimated: 'Time estimated：',
  cancel_task: 'Cancel Task',
  pause_task: 'Pause Task',
  resume_task: 'Resume Task',
  start_task: 'Start Task',
  nomore: 'No more',
  error_100: 'The brush door is open',
  error_101: 'The top cover is open',
  error_114: 'IMU other anomalies',
  error_115: 'IMU data abnormity',
  error_120: 'No data in the communication port',
  error_121: 'Ultrasonic  1 is abnormal',
  error_122: 'Ultrasonic  2 is abnormal',
  error_123: 'Ultrasonic  3 is abnormal',
  error_124: 'Ultrasonic  4 is abnormal',
  error_125: 'Ultrasonic  5 is abnormal',
  error_126: 'Ultrasonic  6 is abnormal',
  error_127: 'Ultrasonic  7 is abnormal',
  error_128: 'Ultrasonic  8 is abnormal',
  error_129: 'Ultrasonic  9 is abnormal',
  error_130: 'Ultrasonic  10 is abnormal',
  error_134: 'Suction machine overcurrent error',
  error_135: 'Brush Motor overcurrent error',
  error_136: 'Sewage discharge linear actuator overcurrent error',
  error_137: 'Suction machine overcurrent linear actuator overcurrent error',
  error_138: 'Brush Motor linear actuator overcurrent error',
  error_139: 'driver borad over-temperature error',
  error_140: 'Retracting abnormal of sewage push rod',
  error_141: 'Extending abnormal of sewage push rod',
  error_160: 'Left driver overcurrent',
  error_161: 'Left driver overload',
  error_162: 'Left motor feedback signal error',
  error_163: 'Left drive overvoltage',
  error_164: 'Left motor undervoltage',
  error_165: 'Left driver overheating',
  error_166: 'Left motor failed to start',
  error_167: 'Left Driver EEP Data Error',
  error_168: 'Left motor overheating',
  error_169: 'Left motor overspeed',
  error_170: 'Left Drive ENCODE Error',
  error_171: 'Left motor initial operation prohibited',
  error_172: 'Left motor external stop',
  error_173: 'Left Driver Hall Sequence Error',
  error_174: 'Left Driver Communication Instruction',
  error_175: 'Left Driver Parameter Setting Error',
  error_176: 'Right drive overcurrent',
  error_177: 'Right Driver Overload',
  error_178: 'Right motor feedback signal error',
  error_179: 'Right Driver Overvoltage',
  error_180: 'Right motor undervoltage',
  error_181: 'Right Horse Driver Overheating',
  error_182: 'Failure of startup of right motor',
  error_183: 'Right Driver EEP Data Error',
  error_184: 'Right motor overheating',
  error_185: 'Right motor overspeed',
  error_186: 'Right Driver ENCODE Error',
  error_187: 'Initial operation of right motor prohibited',
  error_188: 'Right motor external stop',
  error_189: 'Right Driver Hall Sequence Error',
  error_190: 'Right Driver Communication Instruction Abnormal',
  error_191: 'Error setting of right driver parameters',
  error_192: 'Cooling fan abnormal of the control box',
  error_193: 'Flowmeter alarm',
  error_203: 'Ultrasonic  1 is abnormal',
  error_204: 'Ultrasonic  2 is abnormal',
  error_205: 'Ultrasonic  3 is abnormal',
  error_206: 'Ultrasonic  4 is abnormal',
  error_207: 'Ultrasonic  5 is abnormal',
  error_208: 'Ultrasonic  6 is abnormal',
  error_209: 'Ultrasonic  7 is abnormal',
  error_210: 'Ultrasonic  8 is abnormal',
  error_211: 'Ultrasonic  9 is abnormal',
  error_212: 'Ultrasonic  10 is abnormal',
  error_213: 'Ultrasonic  11 is abnormal',
  error_214: 'Ultrasonic  12 is abnormal',
  error_222: 'Lidar anomaly',
  error_223: 'Depth camera 0 abnormal',
  error_224: 'Depth camera 1 abnormal',
  error_225: 'Depth camera 2 abnormal',
  error_226: 'Depth camera 3 abnormal',
  error_227: 'Cooling fan abnormal of the control box',
  error_228: 'Robot configuration file abnormal',
  error_229: 'Depth camera 4 abnormal',
  error_300: 'Pole piece error',
  error_301: 'Servo current error',
  error_302: 'Temperature error',
  error_303: 'Clean water empty error',
  error_304: 'Sewage full error'
}